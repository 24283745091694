// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-commissions-page-js": () => import("/opt/build/repo/src/templates/commissions-page.js" /* webpackChunkName: "component---src-templates-commissions-page-js" */),
  "component---src-templates-murals-page-js": () => import("/opt/build/repo/src/templates/murals-page.js" /* webpackChunkName: "component---src-templates-murals-page-js" */),
  "component---src-templates-leis-page-js": () => import("/opt/build/repo/src/templates/leis-page.js" /* webpackChunkName: "component---src-templates-leis-page-js" */),
  "component---src-templates-paintings-page-js": () => import("/opt/build/repo/src/templates/paintings-page.js" /* webpackChunkName: "component---src-templates-paintings-page-js" */),
  "component---src-templates-product-page-js": () => import("/opt/build/repo/src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-store-page-js": () => import("/opt/build/repo/src/templates/store-page.js" /* webpackChunkName: "component---src-templates-store-page-js" */),
  "component---src-templates-wood-art-page-js": () => import("/opt/build/repo/src/templates/wood-art-page.js" /* webpackChunkName: "component---src-templates-wood-art-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("/opt/build/repo/src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("/opt/build/repo/src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

